import React from 'react'
import Tagscard from './Tagscard';
import Latestnews from './Latestnews';

export default function About() {
  return (
    <div className='about_area'>
        <div className="banner-style">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner">
                            <h1 className="title">About Us</h1>
                            <p className="description">Wherever &amp; whenever you need us. We are here for you – contact us for all your support needs.<br />be it technical, general queries or information support.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="about_post_list_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12">
                        <div className="axil-about-us">
                            <div className="inner">
                                <h3>The Professional Publishing Platform</h3>
                                <p>Aenean consectetur massa quis sem volutpat, a condimentum tortor pretium. Cras id ligula consequat, sagittis nulla at, sollicitudin lorem. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                                <p> Cras id ligula consequat, sagittis nulla at, sollicitudin lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus eleifend, dolor vel condimentum imperdiet.</p>
                                <p>In a professional context it often happens that private or corporate clients corder a publication to be made and presented with the actual content still not being ready. Think of a news blog that’s filled with content hourly on the day of going live. However, reviewers tend to be distracted by comprehensible content, say, a random text copied from a newspaper or the internet. The are likely to focus on the text, disregarding the layout and its elements.</p>
                                <h3>Our Growing News Network</h3>
                                <p>Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline is taken for type specimens: Quo usque tandem abutere, Catilina, patientia nostra? Quam diu etiam furor iste tuus nos eludet? (How long, O Catiline, will you abuse our patience? And for how long will that madness of yours mock us?)</p>
                                <p>Most text editors like MS Word or Lotus Notes generate random lorem text when needed, either as pre-installed module or plug-in to be added. Word selection or sequence don’t necessarily match the original, which is intended to add variety. </p>
                                <h3>The Professional Publishing Platform</h3>
                                <p>Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline is taken for type specimens: Quo usque tandem abutere, Catilina, patientia nostra? Quam diu etiam furor iste tuus nos eludet? (How long, O Catiline, will you abuse our patience? And for how long will that madness of yours mock us?)</p>
                                <p>Most text editors like MS Word or Lotus Notes generate random lorem text when needed, either as pre-installed module or plug-in to be added. Word selection or sequence don’t necessarily match the original, which is intended to add variety.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                    <div className='latest_news'>
                        <h2>Latest News</h2>
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                    </div>
                    <div className='popular_post'>
                        <h2>Popular Post</h2>
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                    </div>
                    <div className='popular_post'>
                        <h2>Tags</h2>
                        <div className='tags'>
                            <Tagscard tagsTxt="News"/>
                            <Tagscard tagsTxt="Business"/>
                            <Tagscard tagsTxt="Health"/>
                            <Tagscard tagsTxt="Politics"/>
                            <Tagscard tagsTxt="Magazine"/>
                            <Tagscard tagsTxt="Sport"/>
                            <Tagscard tagsTxt="Entertainment"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
