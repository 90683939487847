import React from 'react'

export default function Footer() {
  return (
    <div className='footer_area'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='social_link'>
                        <h5 className='follow_title'>Follow Us</h5>
                        <ul className="social-icon">
                            <li><a href="/"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="/"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="/"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="/"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div className='footer_navbar_copyright'>
                        <div className='footer_navbar'>
                            <div className='footer_logo'>Dream Blog</div>
                            <ul className="footer_menu">
                                <li>
                                    <a href="/">About Us</a>
                                </li>
                                <li>
                                    <a href="/">Latest Post</a>
                                </li>
                                <li>
                                    <a href="/">Lifestyle</a>
                                </li>
                                <li>
                                    <a href="/">Gadgets</a>
                                </li>
                                <li>
                                    <a href="/">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className='copyright'>
                            <p>All Rights Reserved © 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
