import React from 'react'
// import {Link} from 'react-router-dom'

export default function Card(props) {
  return (
    <div className='card'>
        <div className="card_thumbnail">
            <img src={props.imgsrc} alt="thumbnail" className='img-fluid' />
        </div>
        <div className="card_content pt--20">
            <div className="card_cat">
                <div className="card_list">
                    <a className="hover_flip_item_wrapper" href="/">
                        <span className="hover_flip_item">
                            <span>{props.category}</span>
                        </span>
                    </a>
                </div>
            </div>
            <h5 className="card_title">
                <a href="/">{props.cardTitle}</a>
            </h5>
        </div>
    </div>
  );
}


