import React from 'react'

export default function Cardg(props) {
  return (
    <div className='trending_card'>
        <div className="inner">
            <a href="/">
                <div className="thumbnail">
                    <img src={props.cardImg} alt="post categories images" />
                </div>
                <div className="content">
                    <h5 className="title">{props.trendingTitile}</h5>
                </div>
            </a>
        </div>
    </div>
  )
}
