import React from 'react'
import Listcard from './Listcard'
import Latestnews from './Latestnews'
import Tagscard from './Tagscard'

export default function Postlist() {
  return (
    <div className='post_list_area'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-6 col-12'>
                    <Listcard listimg="https://templates.envytheme.com/depan/default/assets/img/overview-news/overview-news-1.jpg" listCategory="Lifestyle" listHeading="Lifestyle Fructose in the diet may exacerbate inflammatory bowel disease" listDes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." postDate="28 September, 2024" />
                    <Listcard listimg="https://templates.envytheme.com/depan/default/assets/img/overview-news/overview-news-2.jpg" listCategory="Fashion" listHeading="Lifestyle Fructose in the diet may exacerbate inflammatory bowel disease" listDes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." postDate="28 September, 2024" />
                    <Listcard listimg="https://templates.envytheme.com/depan/default/assets/img/overview-news/overview-news-3.jpg" listCategory="Entertainment" listHeading="Lifestyle Fructose in the diet may exacerbate inflammatory bowel disease" listDes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." postDate="28 September, 2024" />
                    <Listcard listimg="https://templates.envytheme.com/depan/default/assets/img/overview-news/overview-news-4.jpg" listCategory="International" listHeading="Lifestyle Fructose in the diet may exacerbate inflammatory bowel disease" listDes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." postDate="28 September, 2024" />
                    <Listcard listimg="https://templates.envytheme.com/depan/default/assets/img/overview-news/overview-news-5.jpg" listCategory="Politics" listHeading="Lifestyle Fructose in the diet may exacerbate inflammatory bowel disease" listDes="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." postDate="28 September, 2024" />
                </div>
                <div className='col-lg-4 col-md-6 col-12'>
                    <div className='latest_news'>
                        <h2>Latest News</h2>
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-4.jpg" newsTitle="Giving special powers to police officers to prevent crime" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-5.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                    </div>
                    <div className='popular_post'>
                        <h2>Popular Post</h2>
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-4.jpg" newsTitle="Giving special powers to police officers to prevent crime" newsDate="28 September, 2024" />
                        <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-5.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                    </div>
                    <div className='popular_post'>
                        <h2>Tags</h2>
                        <div className='tags'>
                            <Tagscard tagsTxt="News"/>
                            <Tagscard tagsTxt="Business"/>
                            <Tagscard tagsTxt="Health"/>
                            <Tagscard tagsTxt="Politics"/>
                            <Tagscard tagsTxt="Magazine"/>
                            <Tagscard tagsTxt="Sport"/>
                            <Tagscard tagsTxt="Entertainment"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
