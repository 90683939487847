import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar'; 
import About from './components/About';
import Contact from './components/Contact';
import Banner from './components/Banner';
import Cardb from './components/Cardb';
import Postlist from './components/Postlist';



function App() {
  return(
    <>
      <Router>
        <Navbar title="Dream Blog" about="About Us"/>
        <Switch>
            <Route exact activeClassName="active" path="/">
              <Banner />
              <Cardb gtitle="Trending Topics" view="View All" />
              <Postlist />
            </Route>
            <Route exact activeClassName="active" path="/about">
              <About />
            </Route>
            <Route exact activeClassName="active" path="/contact">
              <Contact />
            </Route>
          </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
