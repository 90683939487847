import React from 'react'

export default function Listcard(props) {
  return (
    <div className='list_card_block'>
        <div className='list_card_img'>
            <a href='/'>
                <img src={props.listimg} className='img-fluid' alt='' />
            </a>
        </div>
        <div className='list_card_content'>
            <span>{props.listCategory}</span>
            <h3>
                <a href="/">{props.listHeading}</a>
            </h3>
            <p>{props.listDes}</p>
            <p>{props.postDate}</p>
        </div>
    </div>
  )
}
