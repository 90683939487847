import React from 'react'

export default function Latestnews(props) {
  return (
    <div className='news_block'>
        <div className='thumb'>
            <a href="/">
                <img src={props.newsimg} alt='' className='img-fluid'/>
            </a>
        </div>
        <div className="info">
            <h4 className="title">
                <a href="/">{props.newsTitle}</a>
            </h4>
            <p>{props.newsDate}</p>
        </div>
    </div>
  )
}
