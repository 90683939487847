import React from 'react'
import Tagscard from './Tagscard';
import Latestnews from './Latestnews';
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
    <div className='about_area'>
        <div className="banner-style">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner">
                            <h1 className="title">Contact Us</h1>
                            <p className="description">Wherever  whenever you need us. We are here for you – contact us for all your support needs.<br />be it technical, general queries or information support.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="about_post_list_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12">
                        <div className="axil-about-us">
                            <div className="inner">
                                <h3>Say Hello!</h3>
                                <p>Donec cursus dolor vitae congue consectetur. Morbi mattis viverra felis. Etiam dapibus id turpis at sagittis. Cras mollis mi vel ante ultricies, id ullamcorper mi pulvinar. Proin bibendum ornare risus, lacinia cursus quam condimentum id. Curabitur auctor massa eget porttitor molestie. Aliquam imperdiet dolor nec metus pulvinar sollicitudin.</p>
                                
                                <p>Aliquam iaculis at odio ut tempus. Suspendisse blandit luctus dui, a consequat mauris mollis id. Sed in ante at tortor malesuada imperdiet. Vestibulum sed gravida nibh. Nulla suscipit congue lorem, id tempor ipsum molestie sit amet. Nulla ultricies vitae erat in tincidunt. Maecenas tempus quam et ipsum elementum, a efficitur lectus tincidunt. Praesent diam elit, tincidunt ac tempus vulputate, aliquet viverra mauris. Etiam eu nunc efficitur, sagittis est ut, fringilla neque. Ut interdum eget lorem eget congue. Ut nec arcu placerat, mattis urna vel, consequat diam. Sed in leo in dolor suscipit molestie.</p>
                                <p className="primary-color">Email: <Link to="/">info.web@gmail.com</Link></p>
                            </div>
                        </div>
                        <div className="axil-section-gapTop axil-contact-form-area">
                            <h4 className="title mb-3">Send Us a Message</h4>
                            <p className="b3 mb-3">Your email address will not be published. All the fields are required.</p>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-3 col-12'>
                                        <div className='form-group'>
                                            <label>Your Name</label>
                                            <input type='text' className='form-control' name=''></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-3 col-12'>
                                        <div className='form-group'>
                                            <label>Phone</label>
                                            <input type='text' className='form-control' name=''></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-3 col-12'>
                                        <div className='form-group'>
                                            <label>Email Id</label>
                                            <input type='text' className='form-control' name=''></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='form-group'>
                                            <label>Your Message</label>
                                            <textarea className='form-control' rows='5' name=''></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <button name="submit" type="submit" id="submit" class="btn button-rounded btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className='latest_news'>
                            <h2>Latest News</h2>
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                        </div>
                        <div className='popular_post'>
                            <h2>Popular Post</h2>
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-1.jpg" newsTitle="Negotiations on a peace agreement between" newsDate="28 September, 2024" />
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-2.jpg" newsTitle="Love songs helped me through heartbreak" newsDate="28 September, 2024" />
                            <Latestnews newsimg="https://templates.envytheme.com/depan/default/assets/img/latest-news/latest-news-3.jpg" newsTitle="This movement aims to establish women rights" newsDate="28 September, 2024" />
                        </div>
                        <div className='popular_post'>
                            <h2>Tags</h2>
                            <div className='tags'>
                                <Tagscard tagsTxt="News"/>
                                <Tagscard tagsTxt="Business"/>
                                <Tagscard tagsTxt="Health"/>
                                <Tagscard tagsTxt="Politics"/>
                                <Tagscard tagsTxt="Magazine"/>
                                <Tagscard tagsTxt="Sport"/>
                                <Tagscard tagsTxt="Entertainment"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
