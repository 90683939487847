import React from 'react'
import Card from './Card'

export default function Banner() {
  return (
        <div className="post-banner">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12 col-12">
                        <Card imgsrc="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-tect-01.jpg" category="FEATURED POST" cardTitle="Beauty of deep space. Billions of galaxies in the universe."/>
                    </div>
                    <div className="col-xl-3 col-md-6 mt_lg--30 mt_md-30 mt_sm--30 col-12">
                        <div className="content-block">
                            <Card imgsrc="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-tect-02.jpg" category="LEADERSHIP" cardTitle="Rocket Lab mission fails shortly after launch"/>
                        </div>
                        <div className="content-block mt-4">
                            <Card imgsrc="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-tect-03.jpg" category="PRODUCT UPDATES" cardTitle="The Morning After: Uber sets its sights on Postmates"/>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mt_lg--30 mt_md-30 mt_sm--30 col-12">
                        <div className="content-block">
                            <Card imgsrc="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-tect-04.jpg" category="TECHNOLOGY" cardTitle="Virtual Reality or Artificial Intelligence Technology"/>
                        </div>
                        <div className="content-block mt-4">
                            <Card imgsrc="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-tect-05.jpg" category="GADGET" cardTitle="Air Pods Pro with Wireless Charging Case."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
