import React from 'react'
import Cardg from './Cardg'

export default function Cardb(props) {
  return (
    <div className='trending_gallery'>
        <div className='container'>
            <div className='row'>
                <div className='gallery_title'>
                    <h1>{props.gtitle}</h1>
                    <a href='/'>{props.view}</a>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-01.jpg" trendingTitile="Sport & Fitness"/>
                </div>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-02.jpg" trendingTitile="Travel"/>
                </div>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-03.jpg" trendingTitile="Lifestyle"/>
                </div>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-04.jpg" trendingTitile="Health"/>
                </div>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-05.jpg" trendingTitile="Animals"/>
                </div>
                <div className='col-lg-2 col-md-4 col-4'>
                    <Cardg cardImg="https://new.axilthemes.com/demo/template/blogar/assets/images/post-images/post-sm-06.jpg" trendingTitile="Food & Drink"/>
                </div>
            </div>
        </div>
    </div>
  )
}
